import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext"; // Correct path to SpinContext
import "./Spin.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const BET_OPTIONS_COUNT = 39;

const Spin = () => {
  const [customBetAmount, setCustomBetAmount] = useState(null);
  const [currentStrategy, setCurrentStrategy] = useState("");
  const { session } = useSession();
  const { fetchSpins } = useSpin(); // Use the fetchSpins function from the context
  const navigate = useNavigate();
  const [spinData, setSpinData] = useState({
    session_id: "",
    spin_value: "",
    rbOutcome: "---",
    oeOutcome: "---",
    hlOutcome: "---",
    dozOutcome: "---",
    colOutcome: "---",
    rbBet: 0,
    oeBet: 0,
    hlBet: 0,
    dozBet: 0,
    colBet: 0,
  });

  const [isStrategySelected, setIsStrategySelected] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState(false);

  useEffect(() => {
    if (session) {
      setSpinData((prevData) => ({ ...prevData, session_id: session._id }));
    }
  }, [session]);

  const handleStrategyChange = (strategy) => {
    setCurrentStrategy(strategy);
    setIsStrategySelected(true);
  };

  const handleSpinValueChange = (value) => {
    setSpinData((prevData) => ({ ...prevData, spin_value: value }));
    setCurrentStrategy("");
    setIsStrategySelected(false);
  };

  const handleOutcomeChange = (outcome) => {
    setSpinData((prevData) => {
      const currentOutcome = prevData[`${currentStrategy}Outcome`];
      return {
        ...prevData,
        [`${currentStrategy}Outcome`]:
          currentOutcome === outcome ? "---" : outcome,
        [`${currentStrategy}Bet`]:
          currentOutcome === outcome ? 0 : prevData[`${currentStrategy}Bet`],
      };
    });
  };

  const handleBetAmount = (bet) => {
    if (bet === "custom") {
      return; // Custom bet amount is handled separately
    }
  
    setSpinData((prevData) => {
      const currentBet = prevData[`${currentStrategy}Bet`];
      return {
        ...prevData,
        [`${currentStrategy}Bet`]: currentBet === bet ? 0 : bet,
      };
    });
    setSummaryVisible(true);
  };

  const handleNoBet = async () => {
    setSpinData((prevData) => ({
      ...prevData,
      rbOutcome: "---",
      oeOutcome: "---",
      hlOutcome: "---",
      dozOutcome: "---",
      colOutcome: "---",
      rbBet: 0,
      oeBet: 0,
      hlBet: 0,
      dozBet: 0,
      colBet: 0,
    }));
    setSummaryVisible(false);

    // Save immediately when No Bet is selected
    try {
      const response = await fetch(`${backendUrl}/api/spins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...spinData,
          rbOutcome: "---",
          oeOutcome: "---",
          hlOutcome: "---",
          dozOutcome: "---",
          colOutcome: "---",
          rbBet: 0,
          oeBet: 0,
          hlBet: 0,
          dozBet: 0,
          colBet: 0,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save spin");
      }

      const savedSpin = await response.json();
      console.log("Saved data:", savedSpin);
      await fetchSpins(spinData.session_id); // Fetch spins after adding a new one
      resetForm();
      navigateToDefaultScreen();
    } catch (error) {
      console.error("Error saving spin:", error);
    }
  };

  const handleSave = async () => {
    if (!spinData.spin_value) {
      alert("Please select a spin value");
      return;
    }

    const transformedSpinData = {
      ...spinData,
      rbOutcome:
        spinData.rbOutcome === "win"
          ? "W"
          : spinData.rbOutcome === "loss"
          ? "L"
          : spinData.rbOutcome,
      oeOutcome:
        spinData.oeOutcome === "win"
          ? "W"
          : spinData.oeOutcome === "loss"
          ? "L"
          : spinData.oeOutcome,
      hlOutcome:
        spinData.hlOutcome === "win"
          ? "W"
          : spinData.hlOutcome === "loss"
          ? "L"
          : spinData.hlOutcome,
      dozOutcome:
        spinData.dozOutcome === "win"
          ? "W"
          : spinData.dozOutcome === "loss"
          ? "L"
          : spinData.dozOutcome,
      colOutcome:
        spinData.colOutcome === "win"
          ? "W"
          : spinData.colOutcome === "loss"
          ? "L"
          : spinData.colOutcome,
    };

    try {
      const response = await fetch(`${backendUrl}/api/spins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transformedSpinData),
      });

      if (!response.ok) {
        throw new Error("Failed to save spin");
      }

      const savedSpin = await response.json();
      console.log("Saved data:", savedSpin);
      await fetchSpins(spinData.session_id); // Fetch spins after adding a new one
      resetForm();
      navigateToDefaultScreen();
    } catch (error) {
      console.error("Error saving spin:", error);
    }
  };

  const resetForm = () => {
    setSpinData({
      session_id: session ? session._id : "",
      spin_value: "",
      rbOutcome: "---",
      oeOutcome: "---",
      hlOutcome: "---",
      dozOutcome: "---",
      colOutcome: "---",
      rbBet: 0,
      oeBet: 0,
      hlBet: 0,
      dozBet: 0,
      colBet: 0,
    });
    setCurrentStrategy("");
    setIsStrategySelected(false);
    setSummaryVisible(false);
  };

  const handleCustomBetClick = () => {
    const customBet = prompt('Enter custom bet amount:');
    if (customBet && !isNaN(customBet) && Number(customBet) > 0) {
      setCustomBetAmount(Number(customBet)); // Set custom bet amount
      setSpinData((prevData) => ({
        ...prevData,
        [`${currentStrategy}Bet`]: Number(customBet), // Set custom bet value
      }));
      setSummaryVisible(true); // Show summary after setting custom bet
      setIsStrategySelected(true); // Ensure strategy is selected to show buttons
    } else {
      alert('Please enter a valid number.');
    }
  };

  const navigateToDefaultScreen = () => {
    const defaultScreen = localStorage.getItem("defaultScreen") || "RedBlack";
    switch (defaultScreen) {
      case "OddEven":
        navigate("/stats?tab=OddEven");
        break;
      case "HighLow":
        navigate("/stats?tab=HighLow");
        break;
      case "Dozens":
        navigate("/stats?tab=Dozens");
        break;
      case "Columns":
        navigate("/stats?tab=Columns");
        break;
      default:
        navigate("/stats?tab=RedBlack");
    }
  };

  const handleBack = () => {
    resetForm();
  };

  const getColorClass = (spinValue) => {
    const redNumbers = [
      "1",
      "3",
      "5",
      "7",
      "9",
      "12",
      "14",
      "16",
      "18",
      "19",
      "21",
      "23",
      "25",
      "27",
      "30",
      "32",
      "34",
      "36",
    ];
    const blackNumbers = [
      "2",
      "4",
      "6",
      "8",
      "10",
      "11",
      "13",
      "15",
      "17",
      "20",
      "22",
      "24",
      "26",
      "28",
      "29",
      "31",
      "33",
      "35",
    ];
    if (spinValue === "0" || spinValue === "00") return "green";
    if (redNumbers.includes(spinValue)) return "red";
    if (blackNumbers.includes(spinValue)) return "black";
    return "";
  };


  const getSummaryText = () => {
    if (
      spinData.rbOutcome === "No Bet" &&
      spinData.oeOutcome === "No Bet" &&
      spinData.hlOutcome === "No Bet" &&
      spinData.dozOutcome === "No Bet" &&
      spinData.colOutcome === "No Bet"
    ) {
      return "No Bet";
    }
    const rbText =
      spinData.rbOutcome !== "---" && spinData.rbOutcome !== "No Bet"
        ? `<b>R/B</b> (${spinData.rbOutcome.charAt(0).toUpperCase()}) ${
            spinData.rbBet === 'custom' ? customBetAmount : spinData.rbBet
          }`
        : "";
    const oeText =
      spinData.oeOutcome !== "---" && spinData.oeOutcome !== "No Bet"
        ? `<b>O/E</b> (${spinData.oeOutcome.charAt(0).toUpperCase()}) ${
            spinData.oeBet === 'custom' ? customBetAmount : spinData.oeBet
          }`
        : "";
    const hlText =
      spinData.hlOutcome !== "---" && spinData.hlOutcome !== "No Bet"
        ? `<b>H/L</b> (${spinData.hlOutcome.charAt(0).toUpperCase()}) ${
            spinData.hlBet === 'custom' ? customBetAmount : spinData.hlBet
          }`
        : "";
    const dozText =
      spinData.dozOutcome !== "---" && spinData.dozOutcome !== "No Bet"
        ? `<b>Doz</b> (${spinData.dozOutcome.charAt(0).toUpperCase()}) ${
            spinData.dozBet === 'custom' ? customBetAmount : spinData.dozBet
          }`
        : "";
    const colText =
      spinData.colOutcome !== "---" && spinData.colOutcome !== "No Bet"
        ? `<b>Col</b> (${spinData.colOutcome.charAt(0).toUpperCase()}) ${
            spinData.colBet === 'custom' ? customBetAmount : spinData.colBet
          }`
        : "";
    const parts = [rbText, oeText, hlText, dozText, colText].filter(
      (text) => text
    );
    return parts.join(", ");
  };

  return (
    <div className="spin-page">
      {spinData.spin_value && (
        <button className="back-button" onClick={handleBack}>
          Back
        </button>
      )}

      {!spinData.spin_value && (
        <>
          <div className="zeros-container">
            <div className="first-row-button-container">
              <button
                key="0"
                className={`spin-button first-row-button ${getColorClass("0")}`}
                onClick={() => handleSpinValueChange("0")}
              >
                <span>0</span>
              </button>
            </div>
            <div className="first-row-button-container">
              <button
                key="00"
                className={`spin-button first-row-button ${getColorClass("00")}`}
                onClick={() => handleSpinValueChange("00")}
              >
                <span>00</span>
              </button>
            </div>
          </div>

          <div className="other-numbers-container">
            {Array.from({ length: 36 }, (_, i) => (i + 1).toString()).map(
              (number, index) => (
                <React.Fragment key={number}>
                  <button
                    className={`spin-button ${getColorClass(number)}`}
                    onClick={() => handleSpinValueChange(number)}
                  >
                    <span>{number}</span>
                  </button>
                  {(index + 1) % 12 === 0 && index !== 35 && (
                    <hr className="divider" />
                  )}
                </React.Fragment>
              )
            )}
          </div>
        </>
      )}

      {spinData.spin_value && (
        <>
          <div className="selected-spin-container">
            <button
              className={`selected-spin ${getColorClass(spinData.spin_value)}`}
            >
              <span>{spinData.spin_value}</span>
            </button>
          </div>
          <button onClick={handleNoBet} className="no-bet-button">
            No Bet
          </button>
          <div className="strategy-buttons">
            <button
              onClick={() => handleStrategyChange("rb")}
              className={`strategy-button ${currentStrategy === "rb" ? "active" : ""}`}
            >
              R/B
            </button>
            <button
              onClick={() => handleStrategyChange("oe")}
              className={`strategy-button ${currentStrategy === "oe" ? "active" : ""}`}
            >
              O/E
            </button>
            <button
              onClick={() => handleStrategyChange("hl")}
              className={`strategy-button ${currentStrategy === "hl" ? "active" : ""}`}
            >
              H/L
            </button>
            <button
              onClick={() => handleStrategyChange("doz")}
              className={`strategy-button ${currentStrategy === "doz" ? "active" : ""}`}
            >
              Doz
            </button>
            <button
              onClick={() => handleStrategyChange("col")}
              className={`strategy-button ${currentStrategy === "col" ? "active" : ""}`}
            >
              Col
            </button>
          </div>
          {isStrategySelected && currentStrategy !== "nb" && (
            <div className="outcome-buttons-container">
              <button
                onClick={() => handleOutcomeChange("win")}
                className={`outcome-button win-button ${
                  spinData[`${currentStrategy}Outcome`] === "win" ? "outlined" : ""
                }`}
                disabled={
                  spinData.spin_value === "0" || spinData.spin_value === "00"
                }
              >
                Win
              </button>
              <button
                onClick={() => handleOutcomeChange("loss")}
                className={`outcome-button loss-button ${
                  spinData[`${currentStrategy}Outcome`] === "loss" ? "outlined" : ""
                }`}
              >
                Loss
              </button>
            </div>
          )}
{isStrategySelected &&
  currentStrategy !== "nb" &&
  spinData[currentStrategy + "Outcome"] &&
  spinData[currentStrategy + "Outcome"] !== "---" && (
    <div className="bet-amount-container">
      <div className="bet-grid">
        {[...Array(BET_OPTIONS_COUNT)].map((_, i) => (
          <button
            key={i + 1}
            className={`bet-button ${
              spinData[currentStrategy + 'Outcome'] === 'win' ? 'green' : 'red'
            } ${
              spinData[currentStrategy + 'Bet'] === i + 1 ? 'highlighted' : ''
            }`}
            onClick={() => handleBetAmount(i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <button
          className={`bet-button custom-bet-button ${
            spinData[currentStrategy + 'Outcome'] === 'win' ? 'green' : 'red'
          } ${spinData[currentStrategy + 'Bet'] === 'custom' ? 'highlighted' : ''}`}
          onClick={handleCustomBetClick}
        >
          {customBetAmount !== null ? `${customBetAmount}` : '$$'}
        </button>
      </div>
    </div>
  )}


          {summaryVisible && (
            <div className="summary-text">
              <span dangerouslySetInnerHTML={{ __html: getSummaryText() }} />
            </div>
          )}
          {isStrategySelected &&
            currentStrategy !== "nb" &&
            spinData[currentStrategy + "Bet"] > 0 && (
              <div className="action-buttons">
                <button className="cancel-button" onClick={resetForm}>
                  Cancel
                </button>
                <button className="save-button" onClick={handleSave}>
                  Save
                </button>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default Spin;