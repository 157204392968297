import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const SessionContext = createContext();

export const useSession = () => {
    return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
    const [session, setSession] = useState(() => {
        const storedSession = localStorage.getItem('session');
        return storedSession ? JSON.parse(storedSession) : null;
    });

    const [selectedSpins, setSelectedSpins] = useState(() => {
        const storedSpins = localStorage.getItem('selectedSpins');
        return storedSpins ? JSON.parse(storedSpins) : [];
    });

    useEffect(() => {
        if (session) {
            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem('selectedSpins', JSON.stringify(selectedSpins));
        } else {
            localStorage.removeItem('session');
            localStorage.removeItem('selectedSpins');
        }
    }, [session, selectedSpins]);

    const startSession = (sessionData) => {
        setSession(sessionData);
        setSelectedSpins([]); // Reset spins on new session
    };

    const clearSessionData = () => {
        setSession(null);
        setSelectedSpins([]);
    };

    const endSession = async (note) => {
        if (session) {
            try {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sessions/end`, {
                    sessionId: session._id,
                    post_session_notes: note
                });
                clearSessionData();
            } catch (error) {
                if (error.response && error.response.data.message === "Session not found") {
                    clearSessionData();
                } else {
                    console.error('Error ending session:', error);
                    throw error;
                }
            }
        }
    };

    const openPreviousSession = async (sessionId) => {
        try {
            // End current session if exists
            if (session) {
                await endSession();
            }

            // Open the selected session
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sessions/${sessionId}/open`);
            const openedSession = response.data.session;
            
            // Fetch spins for the opened session
            const spinsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/spins/${sessionId}/spins`);
            const sessionSpins = spinsResponse.data;

            setSession(openedSession);
            setSelectedSpins(sessionSpins);

            return openedSession;
        } catch (error) {
            console.error('Error opening previous session:', error);
            throw error;
        }
    };

    return (
        <SessionContext.Provider value={{ 
            session, 
            startSession, 
            endSession, 
            openPreviousSession,
            selectedSpins, 
            setSelectedSpins 
        }}>
            {children}
        </SessionContext.Provider>
    );
};