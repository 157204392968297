import React, { useEffect, useState, useMemo } from "react";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './HighLow.module.css';
import sharedStyles from './Shared.css';
import UpdateSpinModal from './UpdateSpinModal';

const calculateLookbackPercentages = (spins, lookback, highlightPercentage, aboveOrBelow) => {
  return spins.map((spin, index) => {
    if (index < lookback - 1) {
      return { ...spin, highlightHigh: false, highlightLow: false };
    }

    const lookbackSpins = spins.slice(index - lookback + 1, index + 1);
    const highCount = lookbackSpins.filter(spin => spin.spin_value !== '0' && spin.spin_value !== '00' && parseInt(spin.spin_value) >= 19).length;
    const lowCount = lookbackSpins.filter(spin => spin.spin_value !== '0' && spin.spin_value !== '00' && parseInt(spin.spin_value) < 19).length;
    const highPercentage = Math.round((highCount / lookback) * 100);
    const lowPercentage = Math.round((lowCount / lookback) * 100);

    let highlightHigh = false;
    let highlightLow = false;
    const threshold = highlightPercentage * 100;

    if (aboveOrBelow === 'Above') {
      if (highPercentage > threshold) {
        highlightHigh = true;
      }
      if (lowPercentage > threshold) {
        highlightLow = true;
      }
    } else {
      if (highPercentage < threshold) {
        highlightHigh = true;
      }
      if (lowPercentage < threshold) {
        highlightLow = true;
      }
    }

    return { ...spin, highPercentage, lowPercentage, highlightHigh, highlightLow };
  });
};

const calculateRunningTotals = (spins) => {
  return spins.reduce((acc, spin, index) => {
    const previousTotal = index > 0 ? acc[index - 1].running_total : 0;
    const betAmount = spin.hlBet || 0;
    const outcomeMultiplier = spin.hlOutcome === 'W' ? 1 : spin.hlOutcome === 'L' ? -1 : 0;
    const running_total = previousTotal + betAmount * outcomeMultiplier;
    return [...acc, { ...spin, running_total }];
  }, []);
};

const HighLow = () => {
  const { session } = useSession();
  const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentSpin, setCurrentSpin] = useState(null);

  const [criteria, setCriteria] = useState({
    spinsToLookBack: 5,
    startHighlightingAt: 0.2,
    aboveOrBelow: 'Above'
  });

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria) {
      setCriteria(savedCriteria);
    }
  }, []);

  useEffect(() => {
    if (session?._id && !loading && !spinsFetched) {
      setLoading(true);
      console.log("Fetching spins...");
      fetchSpins(session._id)
        .catch(error => {
          console.error("Error fetching spins:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [session?._id, loading, spinsFetched, fetchSpins]);

  useEffect(() => {
    if (spinsFetched && spins.length === 0) {
      console.log('No spins returned, stopping further API calls.');
    }
  }, [spins, spinsFetched]);

  const handleEditSpin = (spin) => {
    console.log("Editing spin:", spin);
    setCurrentSpin(spin);
    setShowModal(true);
  };

  const handleUpdateSpin = (updatedSpin) => {
    updateSpin(updatedSpin);
    setShowModal(false);
  };

  const handleDeleteSpin = (deletedSpin) => {
    deleteSpin(deletedSpin);
    setShowModal(false);
  };

  const getColor = useMemo(() => {
    return (spinValue) => {
      // console.log('Spin Value:', spinValue); // Log the spin value
      if (spinValue === '0' || spinValue === '00') {
        // console.log('Applying green class'); // Log when green class is applied
        return styles.green; // Green for 0 and 00
      }
      const spinValueInt = parseInt(spinValue, 10);
      if (spinValueInt > 0 && spinValueInt < 19) {
        // console.log('Applying low class'); // Log when low class is applied
        return styles.low; // Black for low values
      }
      if (spinValueInt >= 19) {
        // console.log('Applying high class'); // Log when high class is applied
        return styles.high; // Red for high values
      }
      return '';
    };
  }, []);

  const highlightedSpins = useMemo(() =>
    calculateLookbackPercentages(spins, criteria.spinsToLookBack, criteria.startHighlightingAt, criteria.aboveOrBelow),
    [spins, criteria.spinsToLookBack, criteria.startHighlightingAt, criteria.aboveOrBelow]
  );

  const spinsWithRunningTotals = useMemo(() =>
    calculateRunningTotals(highlightedSpins),
    [highlightedSpins]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.historyTab}>
      <div className={styles.historyContent}>
        <div className={styles.spinHistoryGrid}>
          <div className={styles.spinHistoryHeader}>
            <div className={styles.headerNo}>No.</div>
            <div className={styles.headerSpin}>Spin</div>
            <div className={styles.headerHigh}>High</div>
            <div className={styles.headerLow}>Low</div>
            <div className={styles.headerWl}>W/L</div>
            <div className={styles.headerBet}>Bet</div>
            <div className={styles.headerRt}>RT</div>
          </div>
          {spinsWithRunningTotals.map((spin, index) => (
            <div key={spin._id} className={`${styles.spinRecord} ${getColor(spin.spin_value)}`} onClick={() => handleEditSpin(spin)}>
              <div className={styles.spinNumber}>{index + 1}</div>
              <div className={`${styles.spinValue} ${getColor(spin.spin_value)}`}>
                {spin.spin_value}
              </div>
              <div className={`${styles.highColumn} ${spin.highlightHigh ? styles.highlight : ''}`}>
                {spin.highPercentage}%
              </div>
              <div className={`${styles.lowColumn} ${spin.highlightLow ? styles.highlight : ''}`}>
                {spin.lowPercentage}%
              </div>
              <div className={`${styles.outcome} ${styles[spin.hlOutcome]}`}>
                {spin.hlOutcome === 'win' ? 'W' : spin.hlOutcome === 'loss' ? 'L' : spin.hlOutcome === 'no bet' ? '---' : spin.hlOutcome}
              </div>
              <div className={styles.betAmount}>
                {spin.hlOutcome === 'NB'
                  ? ''
                  : `${spin.hlOutcome === 'L' ? '-' : ''}${spin.hlBet}`}
              </div>
              <div className={styles.runningTotal}>{spin.running_total}</div>
            </div>
          ))}
        </div>
      </div>
      {currentSpin && (
        <UpdateSpinModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          spin={currentSpin}
          onUpdate={handleUpdateSpin}
          onDelete={handleDeleteSpin}
          betType="hl" // Specify the bet type
        />
      )}
    </div>
  );
};

export default HighLow;
