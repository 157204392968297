import React, { useEffect, useState, useMemo } from "react";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './RedBlack.module.css';
import sharedStyles from './Shared.css';
import UpdateSpinModal from '../components/UpdateSpinModal';

const calculateLookbackPercentages = (spins, lookback, highlightPercentage, aboveOrBelow) => {
  const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];

  return spins.map((spin, index) => {
    if (index < lookback - 1) {
      return { ...spin, highlightRed: false, highlightBlack: false };
    }

    const lookbackSpins = spins.slice(index - lookback + 1, index + 1);
    const redCount = lookbackSpins.filter(spin => redNumbers.includes(spin.spin_value.toString())).length;
    const blackCount = lookbackSpins.filter(spin => blackNumbers.includes(spin.spin_value.toString())).length;
    const redPercentage = Math.round((redCount / lookback) * 100);
    const blackPercentage = Math.round((blackCount / lookback) * 100);

    let highlightRed = false;
    let highlightBlack = false;
    const threshold = highlightPercentage * 100;

    if (aboveOrBelow === 'Above') {
      if (redPercentage > threshold) {
        highlightRed = true;
      }
      if (blackPercentage > threshold) {
        highlightBlack = true;
      }
    } else {
      if (redPercentage < threshold) {
        highlightRed = true;
      }
      if (blackPercentage < threshold) {
        highlightBlack = true;
      }
    }

    return { ...spin, redPercentage, blackPercentage, highlightRed, highlightBlack };
  });
};

const calculateRunningTotals = (spins) => {
  return spins.reduce((acc, spin, index) => {
    const previousTotal = index > 0 ? acc[index - 1].running_total : 0;
    const betAmount = spin.rbBet || 0;
    const outcomeMultiplier = spin.rbOutcome === 'W' ? 1 : spin.rbOutcome === 'L' ? -1 : 0;
    const running_total = previousTotal + betAmount * outcomeMultiplier;
    return [...acc, { ...spin, running_total }];
  }, []);
};

const RedBlack = () => {
  const { session } = useSession();
  const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentSpin, setCurrentSpin] = useState(null);

  const [criteria, setCriteria] = useState({
    spinsToLookBack: 5,
    startHighlightingAt: 0.2,
    aboveOrBelow: 'Above'
  });

  

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria) {
      setCriteria(savedCriteria);
    }
  }, []);

  useEffect(() => {
    if (session?._id && !loading && !spinsFetched) {
      setLoading(true);
      console.log("Fetching spins...");
      fetchSpins(session._id)
        .catch(error => {
          console.error("Error fetching spins:", error);
          // Optionally, set an error state here
        })
        .finally(() => setLoading(false));
    }
  }, [session?._id, loading, spinsFetched, fetchSpins]);

  useEffect(() => {
    if (spinsFetched && spins.length === 0) {
      console.log('No spins returned, stopping further API calls.');
    }
  }, [spins, spinsFetched]);
  
  const handleEditSpin = (spin) => {
    console.log("Editing spin:", spin); // Log the spin data
    setCurrentSpin(spin);
    setShowModal(true);
  };
 
  const handleUpdateSpin = (updatedSpin) => {
    updateSpin(updatedSpin);
    setShowModal(false);
  };

  const handleDeleteSpin = (deletedSpin) => {
    deleteSpin(deletedSpin);
    setShowModal(false);
  };

  const getColor = useMemo(() => {
    return (spinValue) => {
      const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
      const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
      if (spinValue === '0' || spinValue === '00') return styles.green;
      if (redNumbers.includes(spinValue.toString())) return styles.red;
      if (blackNumbers.includes(spinValue.toString())) return styles.black;
      return '';
    };
  }, []);

  const highlightedSpins = useMemo(() =>
    calculateLookbackPercentages(spins, criteria.spinsToLookBack, criteria.startHighlightingAt, criteria.aboveOrBelow),
    [spins, criteria.spinsToLookBack, criteria.startHighlightingAt, criteria.aboveOrBelow]
  );

  const spinsWithRunningTotals = useMemo(() =>
    calculateRunningTotals(highlightedSpins),
    [highlightedSpins]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.historyTab}>
      <div className={styles.historyContent}>
        <div className={styles.spinHistoryGrid}>
          <div className={styles.spinHistoryHeader}>
            <div className={styles.headerNo}>No.</div>
            <div className={styles.headerSpin}>Spin</div>
            <div className={styles.headerRed}>Red</div>
            <div className={styles.headerBlack}>Black</div>
            <div className={styles.headerWl}>W/L</div>
            <div className={styles.headerBet}>Bet</div>
            <div className={styles.headerRt}>RT</div>
          </div>
          {spinsWithRunningTotals.map((spin, index) => (
            <div key={spin._id} className={`${styles.spinRecord} ${getColor(spin.spin_value)}`} onClick={() => handleEditSpin(spin)}>
              <div className={styles.spinNumber}>{index + 1}</div>
              <div className={`${styles.spinValue} ${getColor(spin.spin_value)}`}>
                {spin.spin_value}
              </div>
              <div className={`${styles.redColumn} ${spin.highlightRed ? styles.highlight : ''}`}>
                {spin.redPercentage}%
              </div>
              <div className={`${styles.blackColumn} ${spin.highlightBlack ? styles.highlight : ''}`}>
                {spin.blackPercentage}%
              </div>
              <div className={`${styles.outcome} ${styles[spin.rbOutcome]}`}>
                {spin.rbOutcome === 'win' ? 'W' : spin.rbOutcome === 'loss' ? 'L' : spin.rbOutcome === 'no bet' ? '---' : spin.rbOutcome}
              </div>
              <div className={styles.betAmount}>
                {spin.rbOutcome === 'NB'
                  ? ''
                  : `${spin.rbOutcome === 'L' ? '-' : ''}${spin.rbBet}`}
              </div>
              <div className={styles.runningTotal}>{spin.running_total}</div>
            </div>
          ))}
        </div>
      </div>
      {currentSpin && (
        <UpdateSpinModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          spin={currentSpin}
          onUpdate={handleUpdateSpin}
          onDelete={handleDeleteSpin}
          betType="rb" // Specify the bet type
        />
      )}
    </div>
  );
};

export default RedBlack;
