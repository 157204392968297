import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext'; // Adjust the path as needed
import { useSpin } from '../context/SpinContext';
import './EndSession.css'; // Import the CSS file for styling

const EndSession = () => {
  const [note, setNote] = useState('');
  const navigate = useNavigate();
  const { endSession } = useSession();
  const { resetSpins } = useSpin();

  // const handleEndSession = async () => {
  //   if (note.trim()) {
  //     await endSession(note);
  //     resetSpins();
  //     navigate('/');
  //   } else {
  //     alert('Please enter a note before ending the session.');
  //   }
  // };

  const handleEndSession = async () => {
    // Directly end the session without requiring a note
    await endSession(note);
    resetSpins();
    navigate('/');
  };


  return (
    <div className="end-session-container">
      {/* <h2>End Session</h2> */}
      <textarea
        value={note}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Enter your note here..."
        className="note-textarea"
      />
      <button onClick={handleEndSession} className="end-session-button">End Session</button>
    </div>
  );
};

export default EndSession;