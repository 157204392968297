import React, { createContext, useReducer, useContext, useMemo, useCallback } from "react";
import axios from 'axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SpinContext = createContext();

const FETCH_SPINS = 'FETCH_SPINS';
const UPDATE_SPIN = 'UPDATE_SPIN';
const DELETE_SPIN = 'DELETE_SPIN';
const RESET_SPINS = 'RESET_SPINS';

const spinReducer = (state, action) => {
  switch (action.type) {
    case FETCH_SPINS:
      return {
        ...state,
        spins: action.payload,
        frequency: calculateFrequency(action.payload),
        spinsFetched: true // Set the flag when spins are fetched
      };
    case UPDATE_SPIN:
      const updatedSpins = state.spins.map(spin =>
        spin._id === action.payload._id ? action.payload : spin
      );
      return {
        ...state,
        spins: updatedSpins,
        frequency: updateFrequency(state.frequency, action.payload)
      };
    case DELETE_SPIN:
      const filteredSpins = state.spins.filter(spin => spin._id !== action.payload._id);
      return {
        ...state,
        spins: filteredSpins,
        frequency: calculateFrequency(filteredSpins)
      };
    case RESET_SPINS:
      return {
        ...initialState,
        spinsFetched: false // Reset the flag when spins are reset
      };
    default:
      return state;
  }
};

// Define initial state
const initialState = {
  spins: [],
  frequency: {},
  spinsFetched: false, // Add this flag
  criteria: {
    spinsToLookBack: 5,
    startHighlightingAt: 0.2,
    aboveOrBelow: 'Above'
  }
};

const updateFrequency = (currentFrequency, updatedSpin) => {
  const newFrequency = { ...currentFrequency };
  newFrequency[updatedSpin.spin_value] = (newFrequency[updatedSpin.spin_value] || 0) + 1;
  return newFrequency;
};

// Step 3: Add resetSpins function
export const SpinProvider = ({ children }) => {
  const [state, dispatch] = useReducer(spinReducer, initialState);

  const fetchSpins = useCallback(async (session_id) => {
    try {
      const response = await axios.get(`${backendUrl}/api/spins`, { params: { session_id } });
      const sortedData = response.data.sort((a, b) => a._id.localeCompare(b._id));
      const updatedSpins = calculateRunningTotal(sortedData);
      dispatch({ type: FETCH_SPINS, payload: updatedSpins });
    } catch (error) {
      console.error("Error fetching spins:", error);
    }
  }, []);

  const updateSpin = useCallback((updatedSpin) => {
    dispatch({ type: UPDATE_SPIN, payload: updatedSpin });
  }, []);

  const deleteSpin = useCallback((deletedSpin) => {
    dispatch({ type: DELETE_SPIN, payload: deletedSpin });
  }, []);

  const batchUpdate = useCallback((updates) => {
    dispatch({ type: 'BATCH_UPDATE', payload: updates });
  }, []);

  const resetSpins = useCallback(() => {
    dispatch({ type: RESET_SPINS });
  }, []);

  const highlightedSpins = useMemo(() => 
    calculateLookbackPercentages(
      state.spins, 
      state.criteria.spinsToLookBack, 
      state.criteria.startHighlightingAt, 
      state.criteria.aboveOrBelow
    ),
    [state.spins, state.criteria]
  );

  const clearHighlightedSpins = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      spins: [],
      criteria: {
        ...prevState.criteria,
        spinsToLookBack: 0, // or whatever your initial value is
        startHighlightingAt: 0, // or whatever your initial value is
        aboveOrBelow: 'above' // or whatever your initial value is
      }
    }));
  }, []);

  const contextValue = useMemo(() => ({
    state: { ...state, highlightedSpins },
    fetchSpins,
    updateSpin,
    deleteSpin,
    batchUpdate,
    resetSpins,
    clearHighlightedSpins,
    spinsFetched: state.spinsFetched
  }), [state, highlightedSpins, fetchSpins, updateSpin, deleteSpin, batchUpdate, resetSpins, clearHighlightedSpins]);

  return (
    <SpinContext.Provider value={contextValue}>
      {children}
    </SpinContext.Provider>
  );
};

export const useSpin = () => useContext(SpinContext);

const calculateRunningTotal = (spins) => {
  let total = 0;
  return spins.map((spin) => {
    if (spin.outcome === 'W') {
      total += spin.bet_amt;
    } else if (spin.outcome === 'L') {
      total -= spin.bet_amt;
    }
    return { ...spin, running_total: total };
  });
};

const calculateFrequency = (spins) => {
  const initialFreq = {
    0: 0, "00": 0,
    ...Object.fromEntries(Array.from({ length: 36 }, (_, i) => [(i + 1).toString(), 0]))
  };

  return spins.reduce((acc, spin) => {
    acc[spin.spin_value] = (acc[spin.spin_value] || 0) + 1;
    return acc;
  }, initialFreq);
};

const calculateLookbackPercentages = (spins, lookback, highlightPercentage, aboveOrBelow) => {
  return spins.map((spin, index) => {
    if (index < lookback - 1) {
      return { ...spin, highlightRed: false, highlightBlack: false, highlightPurple: false };
    }

    const lookbackSpins = spins.slice(index - lookback + 1, index + 1);
    const redCount = lookbackSpins.filter(spin => parseInt(spin.spin_value) >= 1 && parseInt(spin.spin_value) <= 12).length;
    const blackCount = lookbackSpins.filter(spin => parseInt(spin.spin_value) >= 13 && parseInt(spin.spin_value) <= 24).length;
    const purpleCount = lookbackSpins.filter(spin => parseInt(spin.spin_value) >= 25 && parseInt(spin.spin_value) <= 36).length;
    const redPercentage = Math.round((redCount / lookback) * 100);
    const blackPercentage = Math.round((blackCount / lookback) * 100);
    const purplePercentage = Math.round((purpleCount / lookback) * 100);

    let highlightRed = false;
    let highlightBlack = false;
    let highlightPurple = false;
    const threshold = highlightPercentage * 100;

    if (aboveOrBelow === 'Above') {
      if (redPercentage > threshold) {
        highlightRed = true;
      }
      if (blackPercentage > threshold) {
        highlightBlack = true;
      }
      if (purplePercentage > threshold) {
        highlightPurple = true;
      }
    } else {
      if (redPercentage < threshold) {
        highlightRed = true;
      }
      if (blackPercentage < threshold) {
        highlightBlack = true;
      }
      if (purplePercentage < threshold) {
        highlightPurple = true;
      }
    }

    return { ...spin, redPercentage, blackPercentage, purplePercentage, highlightRed, highlightBlack, highlightPurple };
  });
};