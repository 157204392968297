import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import styles from './Sessions.module.css';
import moment from 'moment-timezone'; // Make sure to install moment-timezone

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Sessions = () => {
    const [sessions, setSessions] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [expandedSessionId, setExpandedSessionId] = useState(null); // State to track expanded session
    const navigate = useNavigate();
    const { openPreviousSession } = useSession();

    useEffect(() => {
        const fetchSessions = async () => {
            console.log('Fetching sessions...');
            try {
                const response = await axios.get(`${backendUrl}/api/sessions`);
                const sortedSessions = response.data.sort((a, b) => 
                    new Date(b.createdOn) - new Date(a.createdOn)
                );
                console.log('Sessions fetched:', sortedSessions);
                setSessions(sortedSessions);
            } catch (error) {
                console.error('Error fetching sessions:', error);
            }
        };

        fetchSessions();
    }, []); // Ensure the dependency array is empty to run only once on mount

    const formatDate = (dateString) => {
        const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    };

    const handleDownloadClick = async (sessionId) => {
        try {
            console.log(`Downloading spins for session: ${sessionId}`);
            const session = sessions.find((s) => s._id === sessionId);
            if (!session) {
                alert('Session not found');
                return;
            }
            const sessionName = session.name;
            const locationName = session.location || '';

            const spinsResponse = await axios.get(`${backendUrl}/api/spins/${sessionId}/spins`);

            if (spinsResponse.data.length === 0) {
                alert('Sorry, there are no spins in this session yet.');
                return;
            }

            const currentDateTime = moment().tz('America/Chicago').format('YYYYMMDDTHHmmss');
            const fileName = `${sessionName}${locationName ? ' - ' + locationName : ''} - ${currentDateTime}.csv`;

            const csvResponse = await axios.get(`${backendUrl}/api/spins/${sessionId}/spins/csv`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([csvResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    const handleNewSessionClick = () => {
        navigate('/settings');
    };

    const handleDeleteClick = (sessionId) => {
        setSelectedSessionId(sessionId);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        if (!selectedSessionId) return;

        try {
            await axios.delete(`${backendUrl}/api/sessions/${selectedSessionId}`);
            setSessions(sessions.filter((session) => session._id !== selectedSessionId));
            setShowDeleteModal(false);
        } catch (error) {
            console.error('Error deleting session:', error);
        }
    };

    const handleCloseModal = () => {
        setShowDeleteModal(false);
    };

    const handleOpenSession = async (sessionId) => {
        const confirmOpen = window.confirm("Are you sure you want to open this previous session?");
        if (confirmOpen) {
            try {
                await openPreviousSession(sessionId);
                navigate(`/settings`);
            } catch (error) {
                console.error('Error opening session:', error);
            }
        }
    };

    const handleViewSession = (sessionId) => {
        navigate(`/session/${sessionId}`);
    };

    const toggleSessionDetails = (sessionId) => {
        setExpandedSessionId(expandedSessionId === sessionId ? null : sessionId);
    };

    return (
        <div className={styles.sessionsContainer}>
            <h1>All Sessions</h1>
            <div className={styles.sessionsTableContainer}>
                <table className={styles.sessionsTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessions.map((session) => (
                            <React.Fragment key={session._id}>
                                <tr onClick={() => toggleSessionDetails(session._id)}>
                                    <td data-label="Name">{session.name}</td>
                                    <td data-label="Location">{session.location}</td>
                                    <td data-label="Actions">
                                        <div className={styles.actionsCell}>
                                            <button className={styles.iconButton} onClick={() => handleDownloadClick(session._id)}>
                                                <i className={`fa fa-download ${styles.downloadIcon}`}></i>
                                            </button>
                                            <button className={styles.iconButton} onClick={() => handleOpenSession(session._id)}>
                                                <i className={`fa fa-folder-open ${styles.folderIcon}`}></i>
                                            </button>
                                            <button className={styles.iconButton} onClick={() => handleDeleteClick(session._id)}>
                                                <i className={`fa fa-trash ${styles.trashIcon}`}></i>
                                            </button>
                                            <button className={styles.iconButton} onClick={() => handleViewSession(session._id)}>
                                                <i className={`fa fa-eye ${styles.eyeIcon}`}></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {expandedSessionId === session._id && (
                                    <tr>
                                        <td colSpan="3">
                                            <div className={styles.sessionDetails}>
                                                <p><strong>Start Date:</strong> {new Date(session.createdOn).toLocaleString()}</p>
                                                <p><strong>Notes:</strong> {session.notes}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            <DeleteConfirmationModal
                show={showDeleteModal}
                handleClose={handleCloseModal}
                handleConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default Sessions;