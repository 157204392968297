import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RedBlack from '../components/RedBlack';
import OddEven from '../components/OddEven';
import HighLow from '../components/HighLow';
import Dozens from '../components/Dozens';
import Columns from '../components/Columns';
import styles from './Stats.module.css';
import { useSpin } from '../context/SpinContext';

const Stats = () => {
  const [activeTab, setActiveTab] = useState('RedBlack');
  const [criteria, setCriteria] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchStats } = useSpin(); 

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria) {
      setCriteria(savedCriteria);
    } else {
      // Navigate to settings only if criteria are not found
      navigate('/settings', { state: { showCriteriaModal: true } });
    }
  }, [navigate, setCriteria]);
  
  return (
    <div className={styles.statsTab}>
      <div className={styles.statsContent}>
        {activeTab === 'RedBlack' && <RedBlack />}
        {activeTab === 'OddEven' && <OddEven />}
        {activeTab === 'HighLow' && <HighLow />}
        {activeTab === 'Dozens' && <Dozens />}
        {activeTab === 'Columns' && <Columns />}
      </div>
      <div className={styles.tabBar}>
        <div
          className={`${styles.tab} ${activeTab === 'RedBlack' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('RedBlack')}
        >
          R/B
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'OddEven' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('OddEven')}
        >
          O/E
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'HighLow' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('HighLow')}
        >
          H/L
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'Dozens' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('Dozens')}
        >
          Doz
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'Columns' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('Columns')}
        >
          Col
        </div>
      </div>
      <div className={styles.originalNavBar}>
        {/* Original navigation bar content here */}
      </div>
    </div>
  );
};

export default Stats;