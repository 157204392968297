import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext';
import axios from 'axios'; // Import Axios
import './Spin.css'; // Import the CSS file

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Spin = () => {
  const [spinValue, setSpinValue] = useState('');
  const [outcome, setOutcome] = useState('');
  const [betAmt, setBetAmt] = useState(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const { session, selectedSpins } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedSpins.length > 0) {
      setSpinValue(selectedSpins[0]); // Set the first selected spin value
    }
  }, [selectedSpins]);

  const handleSpin = (value) => {
    setSpinValue(value);
    setOutcome('');
    setBetAmt(null);
    setShowBackButton(true);
  };

  const handleOutcome = (result) => {
    setOutcome(result);
    if (result === 'NB') {
      setBetAmt(0);
      handleSubmit(spinValue, result, 0);
    }
  };

  const handleBetAmount = (bet) => {
    setBetAmt(bet);
    handleSubmit(spinValue, outcome, bet);
  };

  const handleSubmit = async (spinValue, outcome, betAmt) => {
    if (!session) {
      alert('No active session');
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/api/spins`, {
        session_id: session._id,
        spin_value: spinValue,
        outcome,
        bet_amt: betAmt,
      });

      if (response.status === 201) {
        console.log('Spin recorded successfully');
        reset();
        navigate('/stats');
      }
    } catch (error) {
      console.error('Failed to record spin:', error);
      alert(`Failed to record spin: ${error.response?.data?.error || error.message}`);
    }
  };

  const reset = () => {
    setSpinValue('');
    setOutcome('');
    setBetAmt(null);
  };

  const handleBack = () => {
    setSpinValue('');
    setOutcome('');
    setBetAmt(null);
    setShowBackButton(false);
  };

  return (
    <div className="spin-page">
      {showBackButton && <button className="back-button" onClick={handleBack}>Back</button>}
      {!spinValue && (
        <div className="spin-grid">
          {['0', '00', ...Array.from({ length: 36 }, (_, i) => (i + 1).toString())].map((number) => (
            <button
              key={number}
              className={`spin-button ${getColorClass(number)}`}
              onClick={() => handleSpin(number)}
            >
              <span>{number}</span>
            </button>
          ))}
        </div>
      )}
      {spinValue && (
        <>
          <div className="selected-spin-container">
            <button className={`selected-spin ${getColorClass(spinValue)}`}>
              <span>{spinValue}</span>
            </button>
          </div>
          <div className="outcome-buttons-container">
            <button
              className={`outcome-button win-button ${selectedSpins.includes(spinValue) ? 'outlined' : ''}`}
              onClick={() => handleOutcome('W')}
            >
              Win
            </button>
            <button
              className={`outcome-button loss-button ${selectedSpins.includes(spinValue) ? 'outlined' : ''}`}
              onClick={() => handleOutcome('L')}
            >
              Loss
            </button>
            <button
              className="outcome-button no-bet-button"
              onClick={() => handleOutcome('NB')}
            >
              No Bet
            </button>
          </div>
        </>
      )}
      {outcome && outcome !== 'NB' && (
        <div className="bet-amount-container">
          <div className="bet-grid">
            {[...Array(20)].map((_, i) => (
              <button
                key={i + 1}
                className={`bet-button ${outcome === 'W' ? 'green' : 'red'}`}
                onClick={() => handleBetAmount(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const getColorClass = (spinValue) => {
  const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
  if (spinValue === '0' || spinValue === '00') return 'green';
  if (redNumbers.includes(spinValue)) return 'red';
  if (blackNumbers.includes(spinValue)) return 'black';
  return '';
};

export default Spin;
