import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext'; // Adjust the path as needed
import { useSpin } from '../context/SpinContext';
import './Home.css';

const Home = () => {
  const { session, endSession } = useSession();
  const { resetSpins } = useSpin();
  const navigate = useNavigate();

  const handleSessionClick = async () => {
    if (session) {
      await endSession();
      resetSpins();
      navigate('/');
    } else {
      navigate('/settings');
    }
  };

  return (
    <div className="home-container">
      <h1>Spin Tracker 2</h1>
      <p>v0802.1</p>
      <div className="button-container">
        <button 
          onClick={handleSessionClick} 
          className={`home-button ${session ? 'red' : 'green'}`}
        >
          {session ? 'End Session' : 'Start New Session'}
        </button>
        <Link to="/previous-sessions" className="home-button">View Previous Sessions</Link>
      </div>
    </div>
  );
};

export default Home;
