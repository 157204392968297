import React from "react";
import "./History.css";

const History = () => {
  return (
    <div>
      {/* The History page is currently empty. */}
    </div>
  );
};

export default History;
