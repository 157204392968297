import React, { useState, useEffect } from 'react';
import styles from './AnalysisCriteria.module.css';

const AnalysisCriteria = ({ onClose }) => {
  const [spinsToLookBack, setSpinsToLookBack] = useState('');
  const [startHighlightingAt, setStartHighlightingAt] = useState('');
  const [aboveOrBelow, setAboveOrBelow] = useState('Above');

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria) {
      setSpinsToLookBack(savedCriteria.spinsToLookBack || '');
      setStartHighlightingAt(savedCriteria.startHighlightingAt ? savedCriteria.startHighlightingAt * 100 : '');
      setAboveOrBelow(savedCriteria.aboveOrBelow || 'Above');
    }
  }, []);

  const handleSave = () => {
    const criteria = {
      spinsToLookBack,
      startHighlightingAt: startHighlightingAt / 100,
      aboveOrBelow,
    };
    localStorage.setItem('analysisCriteria', JSON.stringify(criteria));
    onClose();
  };

  return (
    <>
      <div className={styles.modalBackdrop} onClick={onClose}></div>
      <div className={styles.modal}>
        <h2 className={styles.header}>Analysis Criteria</h2>
        <label className={styles.label}>
          Spins to look back:
          <input
            type="number"
            value={spinsToLookBack}
            onChange={(e) => setSpinsToLookBack(e.target.value)}
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Start highlighting at:
          <input
            type="number"
            value={startHighlightingAt}
            onChange={(e) => setStartHighlightingAt(e.target.value)}
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          
          <div className={styles.radioGroup}>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Above"
                checked={aboveOrBelow === 'Above'}
                onChange={(e) => setAboveOrBelow(e.target.value)}
                className={styles.radioInput}
              />
              Above
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Below"
                checked={aboveOrBelow === 'Below'}
                onChange={(e) => setAboveOrBelow(e.target.value)}
                className={styles.radioInput}
              />
              Below
            </label>
          </div>
        </label>
        <div className={styles.buttonContainer}>
          <button className={`${styles.button} ${styles.cancelButton}`} onClick={onClose}>Cancel</button>
          <button className={`${styles.button} ${styles.saveButton}`} onClick={handleSave}>Save</button>
        </div>
      </div>
    </>
  );
};

export default AnalysisCriteria;