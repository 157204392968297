import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { useSession } from './context/SessionContext';

import Home from './pages/Home';
import Spin from './pages/Spin';
import History from './pages/History';
import Stats from './pages/Stats';
import Settings from './pages/Settings';
import Sessions from './pages/Sessions';
import Spins from './pages/Spins';
import UploadSpins from './pages/UploadSpins';
import EndSession from './components/EndSession';
import ViewPreviousSession from './components/ViewPreviousSession';
import './App.css';

const App = () => {
  const { session } = useSession();
  const [borderColor, setBorderColor] = useState('#061AFB'); // Default color

  // Read the color from local storage when the component mounts
  useEffect(() => {
    const storedColor = localStorage.getItem('borderColor');
    if (storedColor) {
      setBorderColor(storedColor);
      document.documentElement.style.setProperty('--border-color', storedColor); // Set CSS variable
    }
  }, []);

  // Function to handle color change and store it in local storage
  const handleColorChange = (color) => {
    setBorderColor(color);
    localStorage.setItem('borderColor', color);
    document.documentElement.style.setProperty('--border-color', color); // Set CSS variable
  };

  // Function to handle session ending
  const handleEndSession = (note) => {
    console.log('Session ended with note:', note);
    // Add your logic to handle the session end
  };

  return (
    <Router>
      <div className="app-container">
        <nav className="bottom-nav">
          <ul>
            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-home"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/spin" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-compact-disc"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/stats" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-chart-bar"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-cog"></i>
              </NavLink>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/spin" element={session ? <Spin /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/history" element={session ? <History /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/history/:sessionId" element={<History />} />
          <Route path="/stats" element={session ? <Stats /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/stats/:sessionId" element={<Stats />} />
          <Route path="/settings" element={<Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/previous-sessions" element={<Sessions />} />
          <Route path="/spins/:sessionId" element={<Spins />} />
          <Route path="/upload-spins" element={<UploadSpins />} />
          <Route path="/end-session" element={<EndSession onEndSession={handleEndSession} />} />
          <Route path="/session/:sessionId" element={<ViewPreviousSession />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;