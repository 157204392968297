import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './ViewPreviousSession.module.css';
import moment from 'moment-timezone'; // Make sure to install moment-timezone

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ViewPreviousSession = () => {
  const { sessionId } = useParams();
  const [session, setSession] = useState(null);
  const [spins, setSpins] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSession, setEditedSession] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [sessionResponse, spinsResponse] = await Promise.all([
          axios.get(`${backendUrl}/api/sessions/${sessionId}`),
          axios.get(`${backendUrl}/api/spins/${sessionId}/spins`)
        ]);
        
        setSession(sessionResponse.data);
        setEditedSession(sessionResponse.data);
        setSpins(spinsResponse.data);
      } catch (error) {
        console.error('Error fetching session details and spins:', error);
      }
    };

    fetchData();
  }, [sessionId]);

  const calculateSessionLength = (start, end) => {
    const startTime = moment(start);
    const endTime = moment(end);
    const duration = moment.duration(endTime.diff(startTime));
    const totalMinutes = duration.asMinutes();
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);

    let result = '';
    if (hours > 0) {
      result += `${hours} ${hours === 1 ? 'hour' : 'hours'} `;
    }
    if (minutes > 0) {
      result += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }
    return result.trim();
  };

  const calculateWinsAndLosses = () => {
    const strategies = {
      rb: { wins: 0, losses: 0, total: 0 },
      oe: { wins: 0, losses: 0, total: 0 },
      hl: { wins: 0, losses: 0, total: 0 },
      doz: { wins: 0, losses: 0, total: 0 },
      col: { wins: 0, losses: 0, total: 0 },
    };

    spins.forEach(spin => {
      const outcomes = {
        rb: { outcome: spin.rbOutcome, bet: spin.rbBet },
        oe: { outcome: spin.oeOutcome, bet: spin.oeBet },
        hl: { outcome: spin.hlOutcome, bet: spin.hlBet },
        doz: { outcome: spin.dozOutcome, bet: spin.dozBet },
        col: { outcome: spin.colOutcome, bet: spin.colBet },
      };

      Object.keys(outcomes).forEach(key => {
        const betAmount = parseFloat(outcomes[key].bet) || 0; // Ensure bet amount is a number
        if (outcomes[key].outcome === 'W') {
          strategies[key].wins += 1;
          strategies[key].total += betAmount; // Add bet amount to total for wins
        } else if (outcomes[key].outcome === 'L') {
          strategies[key].losses += 1;
          strategies[key].total -= betAmount; // Subtract bet amount from total for losses
        }
      });
    });

    return strategies;
  };

  const strategyResults = calculateWinsAndLosses();

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedSession((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`${backendUrl}/api/sessions/${sessionId}`, editedSession);
      setSession(editedSession);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving session details:', error);
    }
  };

  const handleDownloadClick = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/spins/${sessionId}/spins`);

      if (response.data.length === 0) {
        alert('Sorry, there are no spins in this session yet.');
        return;
      }

      const currentDateTime = moment().tz('America/Chicago').format('YYYYMMDDTHHmmss');
      const fileName = `${session.name}${session.location ? ' - ' + session.location : ''} - ${currentDateTime}.csv`;

      const csvResponse = await axios.get(`${backendUrl}/api/spins/${sessionId}/spins/csv`, {
        responseType: 'blob', // Important
      });
      const url = window.URL.createObjectURL(new Blob([csvResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Use the generated file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  if (!session) return <div>Loading...</div>;

  const getColor = (value) => {
    if (value > 0) return 'green';
    if (value < 0) return 'red';
    return 'black';
  };
  

  return (
    <div className={styles.container}>
      <h1>Session Details</h1>
      <i className={`fas fa-arrow-left ${styles.backIcon}`} onClick={() => navigate(-1)}></i> {/* Back Icon */}
      {isEditing ? (
        <div className={styles.editForm}>
          <label>
            Session Name:
            <input
              type="text"
              name="name"
              value={editedSession.name}
              onChange={handleEditChange}
            />
          </label>
          <label>
            Location:
            <input
              type="text"
              name="location"
              value={editedSession.location}
              onChange={handleEditChange}
            />
          </label>
          <label>
            Start Date and Time:
            <input
              type="datetime-local"
              name="createdOn"
              value={moment(editedSession.createdOn).format('YYYY-MM-DDTHH:mm')}
              onChange={handleEditChange}
            />
          </label>
          <label>
            Note:
            <textarea
              name="notes"
              value={editedSession.notes}
              onChange={handleEditChange}
            />
          </label>
          {/* <label>
            Post Session Note:
            <textarea
              name="post_session_notes"
              value={editedSession.post_session_notes}
              onChange={handleEditChange}
            />
          </label> */}
          <button className={styles.button} onClick={handleSave}>Save</button>
        </div>
      ) : (
        <div className={styles.details}>
          <p><strong>Session Name:</strong> {session.name}</p>
          <p><strong>Location:</strong> {session.location}</p>
          <p><strong>Start:</strong> {moment(session.createdOn).format('MMMM Do YYYY, h:mm:ss a')}</p>
          {session.end_date && (
            <>
              <p><strong>End:</strong> {moment(session.end_date).format('MMMM Do YYYY, h:mm:ss a')}</p>
              <p><strong>Session Length:</strong> {calculateSessionLength(session.createdOn, session.end_date)}</p>
            </>
          )}
          {/* <p><strong>Sessions Won:</strong> {Object.values(strategyResults).reduce((acc, strategy) => acc + strategy.wins, 0)}</p>
          <p><strong>Sessions Lost:</strong> {Object.values(strategyResults).reduce((acc, strategy) => acc + strategy.losses, 0)}</p> */}


          {/* <p><strong>Post Session Note:</strong> {session.post_session_notes}</p> */}
          <p><strong>Red/Black:</strong> {strategyResults.rb.wins} W, {strategyResults.rb.losses} L   
  <span style={{ color: getColor(strategyResults.rb.total) }}> &nbsp;
    (${Math.abs(strategyResults.rb.total).toFixed(2)})
  </span>
</p>
<p><strong>Odd/Even:</strong> {strategyResults.oe.wins} W, {strategyResults.oe.losses} L   
  <span style={{ color: getColor(strategyResults.oe.total) }}> &nbsp;
    (${Math.abs(strategyResults.oe.total).toFixed(2)})
  </span>
</p>
<p><strong>High/Low:</strong> {strategyResults.hl.wins} W, {strategyResults.hl.losses} L   
  <span style={{ color: getColor(strategyResults.hl.total) }}> &nbsp;
    (${Math.abs(strategyResults.hl.total).toFixed(2)})
  </span>
</p>
<p><strong>Dozens:</strong> {strategyResults.doz.wins} W, {strategyResults.doz.losses} L   
  <span style={{ color: getColor(strategyResults.doz.total) }}> &nbsp;
    (${Math.abs(strategyResults.doz.total).toFixed(2)})
  </span>
</p>
<p><strong>Columns:</strong> {strategyResults.col.wins} W, {strategyResults.col.losses} L   
  <span style={{ color: getColor(strategyResults.col.total) }}> &nbsp;
    (${Math.abs(strategyResults.col.total).toFixed(2)})
  </span>
</p>

<p><strong>Notes:</strong> {session.notes}</p>


          <div className={styles.iconContainer}>
            <i className={`fas fa-edit ${styles.iconButton}`} onClick={() => setIsEditing(true)}></i> {/* Edit Icon */}
            <i className={`fas fa-download ${styles.iconButton}`} onClick={handleDownloadClick}></i> {/* Download Icon */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewPreviousSession;
