import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { SessionProvider } from './context/SessionContext';
import { SpinProvider } from './context/SpinContext';

const container = document.getElementById('root');
const root = createRoot(container);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => registration.unregister());
  });
}

root.render(
  // <React.StrictMode>
    <SessionProvider>
      <SpinProvider>
        <App />
      </SpinProvider>
    </SessionProvider>
  // </React.StrictMode>
);