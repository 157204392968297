import React from 'react';
import styles from './DeleteConfirmationModal.module.css'; // Assuming you use CSS modules for styles

const DeleteConfirmationModal = ({ show, handleClose, handleConfirm }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this session?</p>
        <div className={styles.modalActions}>
          <button className={styles.btnPrimary} onClick={handleConfirm}>
            Yes
          </button>
          <button className={styles.btnSecondary} onClick={handleClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;