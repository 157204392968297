// ColorPicker.js
import React, { useState, useEffect } from 'react';

const ColorPicker = ({ defaultColor, onColorChange }) => {
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setColor(newColor);
    onColorChange(newColor);
  };

  return (
    <div>
      <label htmlFor="colorPicker">Select Bottom Border Color: </label>
      <input
        type="color"
        id="colorPicker"
        value={color}
        onChange={handleColorChange}
      />
    </div>
  );
};

export default ColorPicker;